import React from "react"
import Layout from "../components/shared/layout/layout"
import SEO from "../components/shared/ui-elements/seo"
import {useIntl} from "gatsby-plugin-intl";
import ChalkItContent from "../components/chalk-it/chalk-it-page";
import  Img from "gatsby-image";
import {graphql} from "gatsby"
const ChalkItPage = props => {
    const intl = useIntl()

    return (
        <Layout>
            <SEO title={intl.formatMessage({id: `seo.chalk-it-page.title`})}
                 description={intl.formatMessage({id: `seo.chalk-it-page.desc`})}/>
            <ChalkItContent />

        </Layout>
    );
}



export default ChalkItPage
