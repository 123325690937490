import React ,{useState} from "react"
import "./chalk.scss"
import {useIntl} from "gatsby-plugin-intl";
import Img from "gatsby-image";
import {graphql, useStaticQuery} from "gatsby"
import Layout from "../shared/layout/layout";
import zIndex from "@material-ui/core/styles/zIndex";


const ChalkItContent = () => {
    const intl = useIntl()
    const [visible,changevisible]=useState(false)
    const [visible1,changevisible1]=useState(false)
    const [visible2,changevisible2]=useState(false)
    const [visible3,changevisible3]=useState(false)
    const [visible4,changevisible4]=useState(false)
    const [visible5,changevisible5]=useState(false)

    {/*{!visible && <span onClick={()=>changevisible(true)} >показати більше</span>}*/}
    const data = useStaticQuery(graphql`
  query {
    luxdecorlogo: file(relativePath: { eq: "luxdecor-logo-chalk-it-300.png" }) {
      childImageSharp {
       fluid(maxWidth: 150) {
        ...GatsbyImageSharpFluid
      }
      }
    },
     chalkItLogo: file(relativePath: { eq: "chalk-it-logo-luxdecor.png" }) {
      childImageSharp {
       fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid
      }
      }
    },

       doMebliChack : file(relativePath: { eq: "creativeUa.png" }) {
      childImageSharp {
         fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
      }
    },
    
       doMebliChackRu : file(relativePath: { eq: "creativeRu.png" }) {
      childImageSharp {
         fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
      }
    },
     farbaKredowaNapis : file(relativePath: { eq: "farba-kredowa-napis-300.png" }) {
      childImageSharp {
       fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
      }
    },
     sekretarzykKolorowy: file(relativePath: { eq: "sekretarzyk_kolorowy-56.jpg" }) {
      childImageSharp {
    fixed(width: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    },
       sekretarzykKolorowyDesctop: file(relativePath: { eq: "sekretarzyk_kolorowy-56.jpg" }) {
      childImageSharp {
       fluid(maxWidth: 700) {
        ...GatsbyImageSharpFluid
      }
      }
    },
     farbaKreditowaChalk: file(relativePath: { eq: "farba-kredowa-chalk-it-300.png" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    },
     farbaKreditowaNapic: file(relativePath: { eq: "farba-kredowa-napis-300.png" }) {
      childImageSharp {
        fixed(width: 170) {
          ...GatsbyImageSharpFixed
        }
      }
    },
     separatorDark: file(relativePath: { eq: "separator-dark.png" }) {
      childImageSharp {
        fixed(width: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    komodaFarbyKredowe: file(relativePath: { eq: "komoda-farby-kredowe.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
      }
    },
      sekretarzykFrancee: file(relativePath: { eq: "sekretarzyk-francce-farby-kredowe.jpg" }) {
      childImageSharp {
    fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
      }
    },
     znaczekHypoalergiczna: file(relativePath: { eq: "icon3Ua.png" }) {
      childImageSharp {
        fixed(width: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    },
       znaczekHypoalergiczna1: file(relativePath: { eq: "icon3Ru.png" }) {
      childImageSharp {
        fixed(width: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    
     znaczekBezpieczenstwo: file(relativePath: { eq: "icon4Ua.png" }) {
      childImageSharp {
        fixed(width: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    
    znaczekBezpieczenstwo1: file(relativePath: { eq: "icon4Ru.png" }) {
      childImageSharp {
        fixed(width: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    
     znaczekNaBazeWodychild: file(relativePath: { eq: "icon3Ua.png" }) {
      childImageSharp {
        fixed(width: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    },
       znaczekNaBazeWodychild1: file(relativePath: { eq: "icon3Ru.png" }) {
      childImageSharp {
        fixed(width: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    
    
        znaczekNaBazeWody: file(relativePath: { eq: "icon1Ua.png" }) {
      childImageSharp {
        fixed(width: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    },
       znaczekNaBazeWody1: file(relativePath: { eq: "icon1Ru.png" }) {
      childImageSharp {
        fixed(width: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    
    
     znaczekMinimum: file(relativePath: { eq: "icon2Ua.png" }) {
      childImageSharp {
        fixed(width: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    },
      znaczekMinimum1: file(relativePath: { eq: "icon2Ru.png" }) {
      childImageSharp {
        fixed(width: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    },
     colorsWeb2: file(relativePath: { eq: "colors_web-02.png" }) {
      childImageSharp {
    fluid(maxWidth: 750) {
        ...GatsbyImageSharpFluid
      }
      }
    },
      colorsWeb1: file(relativePath: { eq: "colors_web-01.png" }) {
      childImageSharp {
       fluid(maxWidth: 750) {
        ...GatsbyImageSharpFluid
      }
      }
    },
      colorsWeb3: file(relativePath: { eq: "colors_web-03.png" }) {
      childImageSharp {
         fluid(maxWidth: 750) {
        ...GatsbyImageSharpFluid
      }
      }
    },
     colorsWeb6: file(relativePath: { eq: "colors_web-06.png" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
        ...GatsbyImageSharpFluid
      }
      }
    },

      colorsWeb7: file(relativePath: { eq: "colors_web-07.png" }) {
      childImageSharp {
          fluid(maxWidth: 750) {
        ...GatsbyImageSharpFluid
      }
      }
    },
     colorsWeb4: file(relativePath: { eq: "colors_web-04.png" }) {
      childImageSharp {
          fluid(maxWidth: 750) {
        ...GatsbyImageSharpFluid
      }
      }
    },

      colorsWeb5: file(relativePath: { eq: "colors_web-05.png" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
        ...GatsbyImageSharpFluid
      }
      }
    },
     colorsWeb8: file(relativePath: { eq: "colors_web-08.png" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
        ...GatsbyImageSharpFluid
      }
      }
    },
     woskBezbarwny: file(relativePath: { eq: "wosk-bezbarwny-chalk-it-zdjecie.png" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    },
     podladGruntujacy: file(relativePath: { eq: "podklad-gruntujacy-chalk-it-zdjecie.png" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    lakierMatowyChalk: file(relativePath: { eq: "lakier-matowy-chalk-it-zdjecie.png" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    },
     odtluszczaczChalk: file(relativePath: { eq: "odtluszczacz-chalk-it-zdjecie.png" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    },
  }
`)
    function one() {
        let val=true;
        console.log(val)
    }
    return (
        <div className="container-chalk">

            <div className="first-container-logo">
                <div className="first-logo">
                    <div className="first-logo-chalk">
                        <div className="first-left-luxdecor" data-sal="slide-down"
                             data-sal-delay="1000"
                             data-sal-duration="1000"
                             data-sal-easing="ease">
                            <Img fluid={data.luxdecorlogo.childImageSharp.fluid}/>
                        </div>
                        <div className="first-wrapper-logo">
                            <div

                                data-sal="fade"
                                data-sal-delay="500"
                                data-sal-duration="1000"
                                data-sal-easing="ease">
                                <Img className="logo-img-under logo-img" fluid={data.chalkItLogo.childImageSharp.fluid}/>

                            </div>
                            <div data-sal="slide-right"
                                 data-sal-delay="700"
                                 data-sal-duration="1000"
                                 data-sal-easing="ease">
                                <Img className="logo-img-under logo-img" fluid={intl.formatMessage({id: `chalk-it.photo.creative`})=="doMebliChack"? data.doMebliChack.childImageSharp.fluid:data.doMebliChackRu.childImageSharp.fluid}/>

                            </div>
                        </div>
                        <div className="first-farba-creditiwa"
                             data-sal="slide-right"
                             data-sal-delay="1000"
                             data-sal-duration="1000"
                             data-sal-easing="ease">
                            <Img fluid={data.farbaKredowaNapis.childImageSharp.fluid}/>
                        </div>
                    </div>
                    {/*data-sal="slide-right"*/}
                    {/*data-sal-delay="0"*/}
                    {/*data-sal-duration="1000"*/}
                    {/*data-sal-easing="ease"*/}
                    <div className="first-logo-img">
                        <div className="first-logo-mobile">
                            <Img fixed={data.sekretarzykKolorowy.childImageSharp.fixed}/>
                        </div>

                        <div className="first-logo-desctop">
                            <Img fluid={data.sekretarzykKolorowyDesctop.childImageSharp.fluid}/>
                        </div>

                    </div>

                </div>
                {/*<div className="mouse-icon">*/}
                {/*    <div className="mouse-inside"></div>*/}
                {/*</div>*/}

            </div>
            <div className="second-container-navbar">
                <div className="second-navbar">
                    <ul>
                        <li><a href="#farbaKreditowa">{intl.formatMessage({id: `chalk-it.nav-bar.kredowa`})}</a></li>
                        <li><a href="#kolory">{intl.formatMessage({id: `chalk-it.nav-bar.colors`})}</a></li>
                        <li><a href="#pozostave-producty">{intl.formatMessage({id: `chalk-it.nav-bar.another-product`})}</a>
                        </li>
                        <li><a href="#video-instruction">{intl.formatMessage({id: `chalk-it.nav-bar.instruction`})}</a></li>
                        {/*<li><a>{intl.formatMessage({id: `chalk-it.nav-bar.blog`})}</a></li>*/}
                        {/*<li><a>{intl.formatMessage({id: `chalk-it.nav-bar.shop`})}</a></li>*/}
                        {/*<li><a>{intl.formatMessage({id: `chalk-it.nav-bar.contact`})}</a></li>*/}
                    </ul>
                </div>
            </div>
            <div id="farbaKreditowa" className="third-container-farbakedrova">
                <div className="first-farbakedrova">
                    <div className="first-farbakedrova-conteiner">
                        <div className="first-farbakedrova-left">
                            <div>
                            <div data-sal="slide-right"
                                 data-sal-delay="0"
                                 data-sal-duration="400"
                                 data-sal-easing="ease"><Img fixed={data.farbaKreditowaChalk.childImageSharp.fixed}/>
                            </div>
                            </div>
                        </div>
                        <div className="first-farbakedrova-right">
                            <div>
                                <Img className="first-farbakedrova-logo"
                                     fixed={data.farbaKreditowaNapic.childImageSharp.fixed}/>

                                <Img className="first-farbakedrova-line"
                                     fixed={data.separatorDark.childImageSharp.fixed}/>

                                <p>
                                    {intl.formatMessage({id: `chalk-it.farba-kredowa.first-desc`})}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="four-container-farbakedrova">
                <div className="second-farbakedrova">
                    <div className="second-farbakedrova-conteiner">
                        <div className="second-farbakedrova-right">
                            <div>
                                <Img className="second-farbakedrova-line"
                                     fixed={data.separatorDark.childImageSharp.fixed}/>

                                <p>
                                    {intl.formatMessage({id: `chalk-it.farba-kredowa.second-desc`})}
                                </p>
                            </div>
                        </div>
                        <div className="second-farbakedrova-left second-farbakedrova-img" data-sal="slide-left"
                             data-sal-delay="0"
                             data-sal-duration="400"
                             data-sal-easing="ease">
                            <Img fluid={data.komodaFarbyKredowe.childImageSharp.fluid}/>

                        </div>
                    </div>
                </div>
            </div>
            <div className="five-container-farbakedrova">
                <div className="third-farbakedrova">
                    <div className="third-farbakedrova-conteiner">
                        <div className="third-farbakedrova-left" data-sal="slide-right"
                             data-sal-delay="0"
                             data-sal-duration="400"
                             data-sal-easing="ease">
                            <Img fluid={data.sekretarzykFrancee.childImageSharp.fluid}/>

                        </div>
                        <div className="third-farbakedrova-right">
                            <div>
                                <Img className="second-farbakedrova-line"
                                     fixed={data.separatorDark.childImageSharp.fixed}/>


                                <p>
                                    {intl.formatMessage({id: `chalk-it.farba-kredowa.third-desc`})}
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="six-container-composition">
                <div className="six-composition">
                    <div className="six-composition-left">
                        <div className="six-composition-img">
                            <Img fixed={intl.formatMessage({id: `chalk-it.photo.znacheck1`})=="znaczekHypoalergiczna"? data.znaczekNaBazeWodychild.childImageSharp.fixed:data.znaczekNaBazeWodychild1.childImageSharp.fixed}/>
                            <Img fixed={intl.formatMessage({id: `chalk-it.photo.znacheck2`})=="znaczekBezpieczenstwo"? data.znaczekBezpieczenstwo.childImageSharp.fixed:data.znaczekBezpieczenstwo1.childImageSharp.fixed}/>
                            {/*<Img fixed={data.znaczekHypoalergiczna.childImageSharp.fixed}/>*/}
                            {/*<Img fixed={data.znaczekBezpieczenstwo.childImageSharp.fixed}/>*/}

                        </div>
                        <Img className="short-line" fixed={data.separatorDark.childImageSharp.fixed}/>

                        <p> {intl.formatMessage({id: `chalk-it.znaczek.first-desc`})}</p>

                    </div>
                    <div className="six-composition-right">
                        <div className="six-composition-img">
                            <Img fixed={intl.formatMessage({id: `chalk-it.photo.znacheck3`})=="znaczekNaBazeWody"? data.znaczekNaBazeWody.childImageSharp.fixed:data.znaczekNaBazeWody1.childImageSharp.fixed}/>
                            <Img fixed={intl.formatMessage({id: `chalk-it.photo.znacheck4`})=="znaczekMinimum"? data.znaczekMinimum.childImageSharp.fixed:data.znaczekMinimum1.childImageSharp.fixed}/>


                        </div>
                        <Img className="short-line" fixed={data.separatorDark.childImageSharp.fixed}/>

                        <p>{intl.formatMessage({id: `chalk-it.znaczek.second-desc`})}</p>

                    </div>
                </div>
            </div>


            <div id="kolory" className="seven-container-forty-colors">
                <div className="top-block-logo colors-logo">
                <span>
                   {intl.formatMessage({id: `chalk-it.product-colors.six-colors`})}
                </span>
                    <Img className="short-line" fixed={data.separatorDark.childImageSharp.fixed}/>

                </div>
                <div className="forty-colors-block colors-first-order ">
                    <div className="forty-colors-img" data-sal="slide-right"
                         data-sal-delay="0"
                         data-sal-duration="400"
                         data-sal-easing="ease"
                        >

                        <Img fluid={data.colorsWeb2.childImageSharp.fluid}/>

                    </div>
                    <div className="forty-colors-palette" data-sal="slide-left"
                         data-sal-delay="400"
                         data-sal-duration="400"
                         data-sal-easing="ease">
                        <Img fluid={data.colorsWeb1.childImageSharp.fluid}/>

                    </div>
                </div>
                <div className="top-block-logo colors-logo">
                <span>
                     {intl.formatMessage({id: `chalk-it.product-colors.thirty-six`})}
                </span>
                    <Img className="short-line" fixed={data.separatorDark.childImageSharp.fixed}/>

                </div>
                <div className="forty-colors-block colors-second-order">
                    <div className="forty-colors-palette" data-sal="slide-right"
                         data-sal-delay="400"
                         data-sal-duration="400"
                         data-sal-easing="ease">
                        <Img fluid={data.colorsWeb3.childImageSharp.fluid}/>
                    </div>
                    <div className="forty-colors-img" data-sal="slide-left"
                         data-sal-delay="0"
                         data-sal-duration="400"
                         data-sal-easing="ease">
                        <Img fluid={data.colorsWeb6.childImageSharp.fluid}/>

                    </div>
                </div>
                <div className="forty-colors-block colors-third-order">
                    <div className="forty-colors-img" data-sal="slide-right"
                         data-sal-delay="0"
                         data-sal-duration="400"
                         data-sal-easing="ease">
                        <Img fluid={data.colorsWeb7.childImageSharp.fluid}/>

                    </div>
                    <div className="forty-colors-palette" data-sal="slide-left"
                         data-sal-delay="400"
                         data-sal-duration="400"
                         data-sal-easing="ease">
                        <Img fluid={data.colorsWeb4.childImageSharp.fluid}/>

                    </div>
                </div>
                <div className="forty-colors-block colors-four-order">
                    <div className="forty-colors-palette" data-sal="slide-right"
                         data-sal-delay="0"
                         data-sal-duration="400"
                         data-sal-easing="ease">
                        <Img fluid={data.colorsWeb5.childImageSharp.fluid}/>

                    </div>
                    <div className="forty-colors-img" data-sal="slide-left"
                         data-sal-delay="400"
                         data-sal-duration="400"
                         data-sal-easing="ease">
                        <Img fluid={data.colorsWeb8.childImageSharp.fluid}/>

                    </div>
                </div>
            </div>
            <div id="pozostave-producty" className="seven-conteiner-product">

                <div className="seven-product">
                    <div className="top-block-logo">
                <span>
                         {intl.formatMessage({id: `chalk-it.product-system.title`})}
                </span>

                        <Img className="short-line" fixed={data.separatorDark.childImageSharp.fixed}/>
                    </div>

                    <div className="seven-product-item">
                        <div className="seven-item">

                            <img className='seven-item__image' src="https://luxdecor.ua/img/farba-kredowa-chalk-it-puszka1.png"/>
                            <h1>{intl.formatMessage({id: `chalk-it.product-system.pure-white`})}</h1>
                            <Img className="short-line" fixed={data.separatorDark.childImageSharp.fixed}/>
                            <div className="seven-product-content">
                                <div  className={`xpandable-block4 ${visible4?'small':'big'}`}>
                                    <p>{intl.formatMessage({id: `chalk-it.product-system.farba-content`})}
                                    </p>
                                </div>
                                <div className='showMore'>
                                    {!visible4 && <span onClick={()=>changevisible4(true)} >{intl.formatMessage({id: `chalk-it.product-system.show-more`})}</span>}
                                    {visible4 && <span onClick={()=>changevisible4(false)} >{intl.formatMessage({id: `chalk-it.product-system.show-less`})}</span>}
                                </div>
                            </div>


                            <span
                                className="Pojemnosc">{intl.formatMessage({id: `chalk-it.product-system.degreaser-format`})}</span>
                            <a href='/product/chalk-it-pure-white'>
                                <button className="inform-about-product">
                                    <span>{intl.formatMessage({id: `chalk-it.product-system.btn-more-information`})}</span>
                                </button>
                            </a>
                        </div>
                        <div className="seven-item">
                            <img className='seven-item__image' src="https://luxdecor.ua/img/farba-kredowa-chalk-it-puszka.png"/>
                            {/*<Img fixed={data.odtluszczaczChalk.childImageSharp.fixed}/>*/}
                            <h1>{intl.formatMessage({id: `chalk-it.product-system.farba-kreidowa`})}</h1>
                            <Img className="short-line" fixed={data.separatorDark.childImageSharp.fixed}/>
                            <div className="seven-product-content">
                                <div  className={`xpandable-block5 ${visible5?'small':'big'}`}>
                                    <p>{intl.formatMessage({id: `chalk-it.product-system.farba-content`})}
                                    </p>
                                </div>
                                <div className='showMore'>
                                    {!visible5 && <span onClick={()=>changevisible5(true)} >{intl.formatMessage({id: `chalk-it.product-system.show-more`})}</span>}
                                    {visible5 && <span onClick={()=>changevisible5(false)} >{intl.formatMessage({id: `chalk-it.product-system.show-less`})}</span>}
                                </div>
                            </div>


                            <span
                                className="Pojemnosc">{intl.formatMessage({id: `chalk-it.product-system.degreaser-format`})}</span>
                            <a href='/product/chalk-it-farba-colors'>
                                <button className="inform-about-product">
                                    <span>{intl.formatMessage({id: `chalk-it.product-system.btn-more-information`})}</span>
                                </button>
                            </a>
                        </div>
                        <div className="seven-item">
                            <Img fixed={data.woskBezbarwny.childImageSharp.fixed}/>
                            <h1>{intl.formatMessage({id: `chalk-it.product-system.wax-title`})}</h1>
                            <Img className="short-line" fixed={data.separatorDark.childImageSharp.fixed}/>
                            <div className="seven-product-content">
                                {/*<label htmlFor="button" className="readMore">...</label>*/}
                                {/*<input type="checkbox" id="button"/>*/}
                                {/*className="xpandable-block"*/}
                                 <div className={`xpandable-block ${visible?'small':'big'}`}  > <p>  {intl.formatMessage({id: `chalk-it.product-system.wax`})}

                                 </p>

                                 </div>
                                <div className='showMore'>
                                {!visible && <span onClick={()=>changevisible(true)} >{intl.formatMessage({id: `chalk-it.product-system.show-more`})}</span>}
                                {visible && <span onClick={()=>changevisible(false)} >{intl.formatMessage({id: `chalk-it.product-system.show-less`})}</span>}
                                </div>
                            </div>
                            <span
                                className="Pojemnosc">{intl.formatMessage({id: `chalk-it.product-system.wax-format`})}</span>
                            <a href='/product/chalk-it-wosk'>
                            <button className="inform-about-product">
                                <span>{intl.formatMessage({id: `chalk-it.product-system.btn-more-information`})}</span>
                            </button>
                            </a>

                        </div>
                        <div className="seven-item">
                            <Img fixed={data.podladGruntujacy.childImageSharp.fixed}/>

                            <h1>{intl.formatMessage({id: `chalk-it.product-system.primer-title`})}</h1>

                            <Img className="short-line" fixed={data.separatorDark.childImageSharp.fixed}/>
                            <div className="seven-product-content">

                                <div className={`xpandable-block1 ${visible1?'small':'big'}`}><p>{intl.formatMessage({id: `chalk-it.product-system.primer`})}</p>
                                </div>
                                <div className='showMore'>
                                {!visible1 && <span onClick={()=>changevisible1(true)} >{intl.formatMessage({id: `chalk-it.product-system.show-more`})}</span>}
                                {visible1 && <span onClick={()=>changevisible1(false)} >{intl.formatMessage({id: `chalk-it.product-system.show-less`})}</span>}
                                </div>
                            </div>

                            <span
                                className="Pojemnosc">{intl.formatMessage({id: `chalk-it.product-system.primer-format`})}</span>
                            <a href='/product/chalk-it-grunt'>
                            <button className="inform-about-product">
                                <span>{intl.formatMessage({id: `chalk-it.product-system.btn-more-information`})}</span>
                            </button>
                            </a>



                        </div>
                        <div className="seven-item">

                            <Img fixed={data.lakierMatowyChalk.childImageSharp.fixed}/>
                            <h1>{intl.formatMessage({id: `chalk-it.product-system.matte-varnish-title`})}</h1>

                            <Img className="short-line" fixed={data.separatorDark.childImageSharp.fixed}/>
                            <div className="seven-product-content">

                          <div  className={`xpandable-block2 ${visible2?'small':'big'}`}>  <p>{intl.formatMessage({id: `chalk-it.product-system.matte-varnish`})}</p>
                          </div>
                                <div className='showMore'>
                                {!visible2 && <span onClick={()=>changevisible2(true)} >{intl.formatMessage({id: `chalk-it.product-system.show-more`})}</span>}
                                {visible2 && <span onClick={()=>changevisible2(false)} >{intl.formatMessage({id: `chalk-it.product-system.show-less`})}</span>}
                                </div>

                                </div>
                            <span
                                className="Pojemnosc">{intl.formatMessage({id: `chalk-it.product-system.varnish-format`})}</span>
                            <a href='/product/chalk-it-lack-matovy'>
                            <button className="inform-about-product">

                                    <span>{intl.formatMessage({id: `chalk-it.product-system.btn-more-information`})}</span>
                            </button>
                            </a>
                        </div>
                        <div className="seven-item">

                            <Img fixed={data.odtluszczaczChalk.childImageSharp.fixed}/>
                            <h1>{intl.formatMessage({id: `chalk-it.product-system.degreaser-title`})}</h1>
                            <Img className="short-line" fixed={data.separatorDark.childImageSharp.fixed}/>
                            <div className="seven-product-content">
                            <div  className={`xpandable-block3 ${visible3?'small':'big'}`}>
                            <p>{intl.formatMessage({id: `chalk-it.product-system.degreaser`})}
                            </p>
                            </div>
                                <div className='showMore'>
                                    {!visible3 && <span onClick={()=>changevisible3(true)} >{intl.formatMessage({id: `chalk-it.product-system.show-more`})}</span>}
                                    {visible3 && <span onClick={()=>changevisible3(false)} >{intl.formatMessage({id: `chalk-it.product-system.show-less`})}</span>}
                                </div>
                            </div>


                            <span
                                className="Pojemnosc">{intl.formatMessage({id: `chalk-it.product-system.degreaser-format`})}</span>
                            <a href='/product/chalk-it-lack'>
                            <button className="inform-about-product">
                                <span>{intl.formatMessage({id: `chalk-it.product-system.btn-more-information`})}</span>
                            </button>
                            </a>
                        </div>
                        {/*newItems*/}

                    </div>

                </div>
            </div>

            <div className='video-container' >
              <h1>{intl.formatMessage({id: `chalk-it.video.video-tile`})}</h1>
                <Img className="short-line" fixed={data.separatorDark.childImageSharp.fixed}/>
                <div className='video-wrapper' id="video-instruction">
                    <div className='chalk-video-first'>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/xGwMer-WQpw" frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </div>
                    <div className='chalk-video-second'>

                        <iframe width="560" height="315" src="https://www.youtube.com/embed/1BC9HHK-BiU" frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ChalkItContent

